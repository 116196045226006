<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import { capitalizeFirstLetter } from '@/components/community/utils/helpers';
import {
  directionalFloor,
  isTomorrow,
  isValidDate,
  isYesterday,
  locale,
  ORDERED_TIME_FORMAT_UNIT,
  RelativeTimeValue
} from '@/components/community/utils/time';
import { useTranslations } from '@/shared/composables/useTranslations';

const {
  date: dateRaw,
  capitalizeFirst = true,
  titleFormat = 'datetime'
} = defineProps<{
  date: Date | string;
  capitalizeFirst?: boolean;
  titleFormat?: 'date' | 'datetime' | 'time';
}>();

const { t } = useTranslations();

const rtf = new Intl.RelativeTimeFormat(locale, {
  numeric: 'auto',
  style: 'long'
});

const getTimeUnit = (d1: Date) => {
  const elapsed = d1.getTime() - Date.now();

  let unit;
  for (unit of ORDERED_TIME_FORMAT_UNIT) {
    const [, unitValue] = unit;
    if (Math.abs(elapsed) > unitValue.delimiter) {
      return unit;
    }
  }

  // return smallest/last as default
  return unit!;
};

const getFormattedTime = (
  date: Date,
  [unitType, unitValue]: [Intl.RelativeTimeFormatUnit, RelativeTimeValue]
) => {
  switch (unitType) {
    // potential overrides
    case 'second': {
      return t('community.shared.time.just_now');
    }

    default: {
      const elapsed = date.getTime() - Date.now();
      const elapsedUnit = elapsed / unitValue.delimiter;

      // special cases
      if (unitType === 'day' && Math.abs(elapsedUnit) < 2) {
        // avoid having too long yesterdays when flooring
        if (elapsedUnit < 0 && !isYesterday(date)) {
          return rtf.format(-2, unitType);
        }
        // avoid having too long tomorrows when flooring
        if (elapsedUnit > 0 && !isTomorrow(date)) {
          return rtf.format(2, unitType);
        }
      }

      const formatted = rtf.format(directionalFloor(elapsedUnit), unitType);
      return capitalizeFirst
        ? capitalizeFirstLetter(formatted)
        : formatted.toLocaleLowerCase();
    }
  }
};

const date = computed(() =>
  typeof dateRaw === 'string' ? new Date(dateRaw) : dateRaw
);
const title = computed(() => {
  if (isValidDate(date.value)) {
    switch (titleFormat) {
      case 'date':
        return date.value.toLocaleDateString(locale);
      case 'datetime':
        return date.value.toLocaleString(locale);
      case 'time':
        return date.value.toLocaleTimeString(locale);
    }
  }
  return '-';
});

const unit = getTimeUnit(date.value);
const text = ref(getFormattedTime(date.value, unit));
const updateInterval = unit?.[1]?.updateInterval;

let interval: number | null = null;

onMounted(() => {
  if (updateInterval) {
    interval = window.setInterval(() => {
      const newValue = getFormattedTime(date.value, getTimeUnit(date.value));

      if (newValue !== text.value) {
        text.value = newValue;
      }
    }, updateInterval);
  }
});

onBeforeUnmount(() => {
  if (interval !== null) {
    window.clearInterval(interval);
  }
});
</script>

<template>
  <span :title="title">{{ text }}</span>
</template>
